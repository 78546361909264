export default class {
  #element
  #iconFrame
  #imageTag
  #label

  constructor(element, iconFrame) {
    this.#element = element
    this.#imageTag = document.createElement('img')
    this.#label = document.createElement('p')
    this.#iconFrame = iconFrame
  }

  setInitialState() {
    this.#imageTag.src = this.#element.dataset.iconEmptyUrl
    this.#label.textContent = 'Subir archivo'
    this.#iconFrame.appendChild(this.#imageTag)
    this.#iconFrame.appendChild(this.#label)
  }

  renderIcon() {
    this.#imageTag.src = this.#iconToRender()
    this.#label.textContent = this.#element.dataset.uploadedFileName
  }

  #iconToRender() {
    return this.#element.dataset.uploadedFileType === 'image' ? this.#element.dataset.iconImageUrl : this.#element.dataset.iconDocumentUrl
  }
}
