// El contexto de este controller es que hay algunas veces que lo que se quiere subir no es una imagen
// por lo que no es necesario renderizar como tal un preview sino solo dar feedback sobre el
// estatus de la subida del documento no es necesario preview no eliminar por lo que se opto
// por agregar un controller y un elemento mas sencillo para estos casos.

import { Controller } from "@hotwired/stimulus"
import ActiveStorageManager from '../image/utils/active_starage_manager'
import ImageManager from '../image/utils/image_manager'
import PreviewManager from './utils/preview_manager'

export default class extends Controller {
  static targets = ['inputFile', 'iconFrame']

  connect() {
    this.inputFile.addEventListener('change', _event => { this.#fileUploadHandle() })
    this.activeStorageManager = new ActiveStorageManager(this.element, this.inputFile)
    this.imageManager = new ImageManager(this.inputFile)
    this.previewManager = new PreviewManager(this.element, this.iconFrame)
    this.previewManager.setInitialState()
  }

  upload() {
    this.inputFile.click()
  }

  #fileUploadHandle() {
    this.imageManager.processFile()
    if (!this.imageManager.fileLoaded()) return

    if(this.#isDirectUploadable) this.activeStorageManager.uploadImage(this.imageManager.getFile())
    this.#manageUploadState()
  }

  #manageUploadState() {
    this.element.setAttribute('data-uploaded-file-name', this.imageManager.getFileName())
    this.element.setAttribute('data-uploaded-file-type', this.imageManager.fileType())
    this.element.classList.add('uploaded')
    this.previewManager.renderIcon()
  }

  get inputFile() {
    return this.inputFileTarget
  }

  get iconFrame() {
    return this.iconFrameTarget
  }

  get #isDirectUploadable() {
    return this.element.dataset.directUpload === 'true'
  }
}
