import ImageCompressor from '../../../../utils/image_compressor'

export default class {
  #fileInput
  #file

  constructor(fileInput) {
    this.#fileInput = fileInput
  }

  async processFile() {
    this.#file = this.#fileInput.files[0]
    if(!this.#file) return

    if(this.#isAnImage()) {
      const imageCompressor = new ImageCompressor(this.#file, 0.98)
      this.#file = await imageCompressor.compressImage()
    }
  }

  getFile() {
    return this.#file
  }

  getFileUrl() {
    return URL.createObjectURL(this.#file)
  }

  getFileName() {
    return this.#file.name
  }

  fileType() {
    return this.#isAnImage() ? 'image' : 'file'
  }

  fileLoaded() {
    return this.#fileInput.files.length > 0
  }

  #isAnImage() {
    return this.#file && this.#file['type'].split('/')[0] === 'image'
  }
}
