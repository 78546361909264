// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbo from "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "controllers"
import addFields from '../nested_forms/add_fields'
import removeFields from '../nested_forms/remove_fields'

Rails.start()
ActiveStorage.start()
new addFields()
new removeFields()

import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/js/src/dropdown'

document.addEventListener('turbolinks:load', () => {
  Rails.refreshCSRFTokens()
})
